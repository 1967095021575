import { unauthenticatedClient } from './axiosClient';
import { CookieKeyEnum } from '~/types';

export async function authCustomer(order_identifier: string, dob: string) {
  await unauthenticatedClient
    .post<{ token: string }>('/auth', {
      order_identifier,
      dob,
    })
    .then((res) => {
      useCookie(CookieKeyEnum.TOKEN).value = res.token;
    });
}
